import React from 'react';

import Root from 'modules/root';

import {initializeApp} from '@renofi/modules-internal';
import polyfills from '@renofi/utilities/src/polyfills';

import './index.css';

import * as appConfig from './config';
import initAnalytics from './analytics';

const {firstRound, secondRound} = polyfills();

function init() {
  initializeApp({
    appConfig,
    onInit: async ({config, storage}) => {
      await initAnalytics({config, storage});
    },
    rootComponent: <Root />,
  });
}

Promise.all(firstRound)
  .then(() => Promise.all(secondRound))
  .then(() => init())
  .catch((error) => {
    console.error('Failed fetching polyfills', error);
  });
